import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs';
import { Message } from '../app/models/message';
import { RelationshipLink } from '../app/models/relationshipLink';
import { MessageActionState } from '../app/models/messageActionState';

@Injectable()
export class ContactService
{
  public headers: Headers;
  public response : any;

  constructor(private http: Http) {

  }

  public sendMessage(name: string, email: string, tel: string, contentMessage: string) {

    const message = new Message();

    message.SenderRecipient = new RelationshipLink();
    message.ActionState = new MessageActionState();

    message.ContentMessage = contentMessage;
    message.SenderRecipient.Sender = name;
    message.SenderRecipient.SenderEmail = email;
    message.SenderRecipient.SenderPhone = tel;
    message.ActionState.opened = false;
    message.ActionState.deleted = false;
    message.ActionState.deletedForEver = false;

    message.Platform = 0;

    this.http.post("http://www.customersvault.com/api/resumemessage", message, { headers: this.headers }).subscribe(
      res => {
         res.json();
      });

    this.response = 'Your message was sent';
  }

  public _setAuthHeaders() {
    this.headers = new Headers();
    // this.headers.append('Authorization', user.token_type + " " + user.access_token);
    this.headers.append('Content-Type', 'application/json');
  }
}
